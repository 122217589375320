const Actions = {
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAIL: "LOGIN_FAIL",
  INCORRECT_CREDENTIALS: "INCORRECT_CREDENTIALS",
  LOGOUT: "LOGOUT",
  SIGNUP: "SIGNUP",
  SIGNUP_FAILED: "SIGNUP_FAILED",
  LOANLOGIN: "LOANLOGIN",
  UPDATE_SUCCESS: "UPDATE_SUCCESS",
  UPDATE_FAILED: "UPDATE_FAILED",
};

export default Actions;
