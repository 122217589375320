import React from 'react'

const LawFirms = () => {
    return (
        <div className="flex flex-col justify-around my-24 items-center">
            <p style={{ fontWeight: 600, fontSize: 28, width: '82%', marginBottom: '20px' }}>Law Firms</p>
            <table class="table" style={{ width: '80%' }}>
                <thead>
                    <tr>

                        <th scope="col" style={{ background: 'rgb(245,245,245)' }}>Law Firms</th>
                        <th scope="col" style={{ background: 'rgb(245,245,245)' }}>Clients</th>

                    </tr>
                </thead>
                <tbody>
                    {
                        [...Array(10)].map((a, i) => {
                            return (
                                <tr>
                                    <td style={{ color: 'gray' }}>{`Edward King ${i}`}</td>
                                    <td style={{ color: 'gray' }}>{`Client ${i}`}</td>
                                </tr>
                            )
                        })
                    }

                </tbody>
            </table>
        </div>
    )
}

export default LawFirms