import React from "react";
import "./savedforms.css";

const SavedForms = () => {
  return (
    <div className="saved-forms-root-container">
      <div className="flex flex-col justify-around my-24 items-center">
        <p
          style={{
            fontWeight: 600,
            fontSize: 28,
            width: "82%",
            marginBottom: "20px",
          }}
        >
          Saved Forms
        </p>
        <table class="table" style={{ width: "80%" }}>
          <thead>
            <tr>
              <th scope="col" style={{ background: "rgb(245,245,245)" }}>
                Document Name
              </th>
              <th scope="col" style={{ background: "rgb(245,245,245)" }}>
                Data Saved
              </th>
              <th scope="col" style={{ background: "rgb(245,245,245)" }}>
                Type
              </th>
              <th scope="col" style={{ background: "rgb(245,245,245)" }}></th>
              <th scope="col" style={{ background: "rgb(245,245,245)" }}></th>
            </tr>
          </thead>
          <tbody>
            {[...Array(10)].map((a, i) => {
              return (
                <tr>
                  <td style={{ color: "gray" }}>{`Document_Name.pdf ${i}`}</td>
                  <td style={{ color: "gray" }}>{`3/14/1 ${i}`}</td>
                  <td style={{ color: "gray" }}>{`PDF`}</td>
                  <td style={{ color: "gray" }}>
                    <button className=" text-basic font-semibold">View</button>
                  </td>
                  <td style={{ color: "gray" }}>
                    <button className=" text-basic font-semibold">
                      DOWNLOAD
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SavedForms;
