import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Button from "../components/Button";
import { auth } from "../Store/Actions/Auth";
import { loanauth } from "../Store/Actions/loanAuth";
import ConfigureStore from "../Store/Store";
import { useHistory } from "react-router";
import { connect, useDispatch } from "react-redux";
import eye from "../assets/images/eye.svg";
import hide from "../assets/images/hide.svg";
import toast from 'react-hot-toast';
import "./loginloan.css";

const LoginLoan = (props) => {
  const dispatch = useDispatch();
  const [loanCredentials, setLoanCredentials] = useState({
    email: "",
    password: "",
  });
  const [visibility, setVisibility] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const { store } = ConfigureStore();
  const history = useHistory();

  console.log(props.redirectTo);

  useEffect(() => {
    setRedirect(props.redirectTo);
    if (redirect === "/nexusloan") {
      history.push(props.redirectTo);
    }
  }, [props.redirectTo, redirect]);

  useEffect(() => {
    if (!props.accessToken) {
      history.push("/");
    }
  }, [props.accessToken]);

  useEffect(() => {
    setInvalid(props.invalidUser);
    if (invalid) {
      return;
    }
  }, [props.invalidUser, invalid]);
  // useEffect(() => {
  //   if (props.accessToken) {
  //     history.push("/clientlist");
  //   }
  // }, [props.accessToken]);
  // useEffect(() => {
  //   setRedirect(props.redirectTo);
  //   if (props.redirectTo) {
  //     history.push(props.redirectTo);
  //   }
  // }, [props.redirectTo, redirect]);

  const handleLoanLogin = (e) => {
    e.preventDefault();
    console.log(loanCredentials);
    let { email, password } = loanCredentials;
    if (email != "" && password != "") {
      dispatch(
        loanauth({
          email: email,
          password: password,
        })
      );
    } else {
      toast("Please enter email and password both fields are required")
    }
  };

  return (
    <div className="loginloan-root-one">
      <Header />
      <div className="loginloan-root-container rounded-xl">
        <form onSubmit={handleLoanLogin}>
          <h3 className="text-xl font-semibold text-center mb-4">
            Please use your Nova Satus credentials to login
          </h3>
          <div className="flex flex-col mx-6 space-y-6">
            <input
              style={{ background: "#F1F6FB" }}
              value={loanCredentials.email}
              className="pl-2 text-base font-medium py-2"
              placeholder="FSA ID, Username, Email or Number"
              type="email"
              onChange={(e) =>
                setLoanCredentials({
                  ...loanCredentials,
                  email: e.target.value,
                })
              }
            />
            <input
              style={{ background: "#F1F6FB" }}
              value={loanCredentials.password}
              className="pl-2 text-base font-medium py-2"
              placeholder="Password"
              type={visibility ? "text" : "password"}
              onChange={(e) =>
                setLoanCredentials({
                  ...loanCredentials,
                  password: e.target.value,
                })
              }
            />
            {visibility ? (
              <img
                src={eye}
                className="loanpass-eye cursor-pointer"
                onClick={() => setVisibility(false)}
              />
            ) : (
              <img
                src={hide}
                className="loanpass-eye cursor-pointer"
                onClick={() => setVisibility(true)}
              />
            )}
            <Button
              type="submit"
              buttonStyle="btn--major--solid"
              buttonSize="btn--medium"
            >
              Login
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    redirectTo: state.auth.redirectTo,
    invalidUser: state.auth.invalidUser,
    accessToken: state.auth.accessToken,
    loanContext: state.auth.loanContext,
  };
};

export default connect(mapStateToProps, { auth })(LoginLoan);
