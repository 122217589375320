import React from "react";
import Header from "../components/Header";
import SubscriptionCard from "../components/SubscriptionCard";

const SubscriptionPage = () => {
  return (
    <div>
      <div className="grid grid-cols-1">
        <SubscriptionCard />
      </div>
    </div>
  );
};

export default SubscriptionPage;
