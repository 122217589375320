import React from "react";
import { Link } from "react-router-dom";
import Button from "../components/Button";
import "./card.css";

const Card = (props) => {
  const doubleButtons = props.doubleButtons;
  const isSettings = props.isSettings;
  const link = props.link;
  const link2 = props.link2;
  const smallButton = props.smallButton;
  return (
    <div
      className="m-3 xs:p-0 sm:p-10 flex rounded-2xl"
      style={{ background: "#ffff", maxHeight: "200px" }}
    >
      <div className="card-div items-center justify-center">
        <img className="card-image" src={props.imgsrc} alt="random" />
      </div>
      <div className="m-2 space-y-2 flex flex-col">
        <h2 className="text-2xl">{props.label}</h2>
        <p className="text-gray-400">{props.description}</p>
        {isSettings ? (
          <div className="flex">
            <p className="text-color p-2 cursor-pointer">
              <Link to={link} className="card-links">
                VIEW
              </Link>
            </p>
          </div>
        ) : smallButton ? (
          <div className="flex space-x-8">
            <Link to={link}>
              <Button
                buttonStyle="btn--major--solid"
                buttonSize="btn--small"
                type="button"
              >
                {props.buttonLabel}
              </Button>
            </Link>
          </div>
        ) : doubleButtons ? (
          <div className="flex space-x-8">
            <Link to={link}>
              <Button
                buttonStyle="btn--major--solid"
                buttonSize="btn--small"
                type="button"
              >
                {props.buttonLabel}
              </Button>
            </Link>

            {doubleButtons ? (
              <Link to={link2}>
                <Button
                  buttonStyle="btn--minor--outline"
                  buttonSize="btn--small"
                  type="button"
                >
                  View List
                </Button>
              </Link>
            ) : null}
          </div>
        ) : (
          <div className="flex space-x-8">
            <Link to={link}>
              <Button
                buttonStyle="btn--major--solid"
                buttonSize="btn--medium"
                type="button"
              >
                {props.buttonLabel}
              </Button>
            </Link>
            {doubleButtons ? (
              <Link to={link2}>
                <Button
                  buttonStyle="btn--minor--outline"
                  buttonSize="btn--medium"
                  type="button"
                >
                  View List
                </Button>
              </Link>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default Card;
