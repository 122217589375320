import { Link, useHistory } from "react-router-dom";
import Button from "../components/Button";
import { useDispatch } from "react-redux";
import React, { useState } from "react";
import Header from "../components/Header";
import "./resetpassword.css";
import axios from "axios";
import toast from 'react-hot-toast';
import message from "../assets/images/message 1.png";

const ResetPassword = () => {
  const [reset, setReset] = useState({
    email: "",
  });
  const [emailSent, setEmailSent] = useState(false);

  // const dispatch = useDispatch();
  const history = useHistory();

  const handlePasswordReset = (e) => {
    e.preventDefault();
    // console.log(reset);
    const data = JSON.stringify(reset);

    var config = {
      method: "post",
      url: "http://www.novasatus.life/rest-auth/password/reset/",
      headers: {
        "Content-Type": "application/json",
      },

      data: data,
    };
    axios(config)
      .then((res) => {
        console.log(res);
        toast("Verification email was sent to your email address!");
        setEmailSent(true);
        // history.push("/resetpass2");
      })
      .catch(() => {
        toast(
          "Sorry, this email does not exist! Try to enter an existing email"
        );
      });
  };

  return (
    <div className="resetpass-root-one">
      <Header />
      {emailSent ?
        <div className="resetpass-root-container rounded-2xl">
          <div className="flex justify-center">
            <img src={message} />
          </div>
          <div className="">
            <h3 className="px-14 pt-6 text-xl font-semibold pb-6 text-center">
              We've received your request.
            </h3>
          </div>
          <div className="flex flex-col mx-6 space-y-6">
            <p className="text-center">
              Please check your email, an instruction <br /> letter will be sent
              to it.
            </p>
            <p className="text-center">
              <span
                className="text-color cursor-pointer"
                onClick={() => history.push("/login")}
              >
              Go To Login
            </span>
            </p>
          </div>
        </div>
        :
        <div className="resetpass-root-container rounded-2xl">
        <h3 className="text-xl text-center py-6">Reset your password</h3>
        <form onSubmit={handlePasswordReset}>
          <div className="flex flex-col mx-6 space-y-6 justify-center items-center pb-10">
            <p className="text-center pb-6  ">
              Please enter your email, an instruction <br/> letter will be sent
              to it.
            </p>
            <input
              style={{width: 300}}
              className="pl-2 text-base py-2"
              placeholder="Email"
              type="email"
              required
              value={reset.email}
              onChange={(e) => setReset({...reset, email: e.target.value})}
            />
          </div>
          <div className=" flex justify-center">
            <Button type="submit">Reset Password</Button>
          </div>
        </form>

        <div className="text-center">
          <p className="text-color p-3 ">
            <Link to="/login" className="links-secondary">
              I remember my password
            </Link>
          </p>
        </div>
      </div>}
    </div>
  );
};

export default ResetPassword;
