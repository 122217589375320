import Actions from "../Type.js";

const initialState = {
  user: localStorage.getItem("user"),
  emaill: localStorage.getItem("user_email"),
  accessToken: localStorage.getItem("token"),
  isType: localStorage.getItem("user_type"),
  isAuthenticated: !!localStorage.getItem("token"),
  uid: localStorage.getItem("uid"),
  borrower_id: localStorage.getItem("borrower_id"),
  invalidUser: false, // whether use typed invalid email or password during login
  redirectTo: false, // successfull redirect to dashboard on successfull login
  isLoading: false, //loading during login
  loanContext: false,
  //refreshToken: "",
};

const AuthReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case Actions.LOGIN_SUCCESS:
      localStorage.setItem("token", payload.key);
      localStorage.setItem("user", payload.user.name);
      localStorage.setItem("uid", payload.user.id);
      localStorage.setItem("user_type", payload.user.user_type);
      localStorage.setItem("borrower_id", payload.user.borrower_id);

      localStorage.setItem("user_email", payload.user.email);

      console.log("borrower-id--", payload.user.borrower_id);
      // console.log("user-id--", payload.user);


      const isType = payload.user.user_type;
      const user = payload.user.name;
      const email = payload.user.email;

      return {
        ...state,
        isAuthenticated: true,
        isType: payload.user.user_type,
        borrower_id: payload.user.borrower_id,
        user: user,
        email: email,
        uid: payload.user.id,
        accessToken: payload.key,
        invalidUser: false,
        redirectTo:
          isType === "law_firm" && state.loanContext === false
            ? "/studenthomepage"
            : isType === "law_firm" && state.loanContext === true
              ? "/nexusloan"
              : isType === "student" && state.loanContext === false
                ? "/studenthomepage"
                : isType === "student" && state.loanContext === true
                  ? "/nexusloan"
                  : null,
      };

    case Actions.LOANLOGIN:
      localStorage.setItem("token", payload.key);
      localStorage.setItem("user", payload.user.name);
      localStorage.setItem("uid", payload.user.id);
      localStorage.setItem("user_type", payload.user.user_type);

      return {
        ...state,
        isAuthenticated: true,
        loanContext: true,
        uid: payload.user.id,
        accessToken: payload.key,
        invalidUser: false,
        redirectTo: "/nexusloan",
      };

    case Actions.LOGOUT:
      console.log("mob");
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("uid");
      localStorage.removeItem("user_type");
      return {
        ...state,
        accessToken: null,
        user: null,
        uid: null,
        isAuthenticated: null,
        invalidUser: false,
        loanContext: false,
        redirectTo: "/",
        isType: null,
      };

    case Actions.LOGIN_FAIL:
    case Actions.INCORRECT_CREDENTIALS:
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      return {
        ...state,
        accessToken: null,
        user: null,
        isAuthenticated: false,
        invalidUser: true,
        loanContext: false,
        redirectTo: false,
      };
    default:
      return state;
  }
};

export default AuthReducer;
