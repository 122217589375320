import React, { useEffect, useState } from 'react'
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { useHistory } from "react-router";

export default function Checkout(props) {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  const [errorMessage, setErrorMessage] = useState(null);

  const loadScript = (src) => new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) {
      // return resolve()
    }
    const script = document.createElement('script')
    script.src = src
    script.onload = () => resolve()
    script.onerror = (err) => reject(err)
    document.body.appendChild(script)
  })

  useEffect(() => {
    //const src = 'https://payitoff-cdn.io/sandbox/nexus/js/v1'
    const src = 'https://payitoff-cdn.io/nexus/js/v1'

    loadScript(src)
      .then(() => {
        /* global Nexus */
        Nexus({
          //nexus_key: '4cf0d535a934aba6575a2db6121dfffd61587dd60f17cc959d1e2c6a0de4d31d',
          nexus_key: '8091f0ca5bbdf8c2f5c7549b1c14bd4c0777a987612449286bbd80e8aeb2225f',
          borrower: props.user + props.last_name + props.id
          //borrower: "charlesdemo1"
        })
        console.log(Nexus)
      })
      .catch(console.error)

    const clientSecret = new URLSearchParams(window.location.search).get('redirect_status');
  
    if (!clientSecret) {
      return;
    }

  }, [])

  const handleSubmit = async (event) => {
    // we don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    
    if (!stripe || !elements) {
      // stripe.js has not yet loaded.
      return;
    }
    
    const { error } = await stripe.confirmPayment({
      // `Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: 'http://www.novasatus.life/paymentstripe',
        // return_url: "http://localhost:3000/paymentstripe",
      },
      redirect: "if_required"
    });
    
    
    if (error) {
      setErrorMessage(error.message);
    } else {
      console.log("Payment successful!");
      props.setPaymentCompleted(true);
      onEnroll()
    }
  };

  const onEnroll = () => {
    Nexus.enroll({
      // the function to call when borrower is done
      onSuccess: (response) => {
        // we'll just console.log our response so we can inspect it
        console.log(response)
        //navigation.navigate('HomeStudent')
        history.push('studenthomepage');
      },
      // the function to call when borrower quits or error occurs
      onExit: (response) => {
        // we'll just console.log our response so we can inspect it
        console.log(response)
      },
    })
  }
  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button 
        disabled={!stripe}
        style={{ borderRadius: 4,
          height: 58,
          width: 300,
          marginTop: 10,
          fontSize: 18,
          backgroundColor: '#FBB03B',
          borderColor: '#FBB03B',
          color: '#fff',
          justifyContent: 'center',
          marginLeft: "40%",
          alignItems: 'center' }}>Pay $40.00</button>
      {errorMessage && <div style={{ color: 'red', 
        alignSelf: 'center', 
        marginTop: 5 }}>{errorMessage}</div>}
    </form>
  );
}