import React, { useEffect, useState, useContext } from "react";
import { TypeContext } from "../context/TypeContext";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import { auth } from "../Store/Actions/Auth";

const Users = (props) => {
  const history = useHistory();

  useEffect(() => {
    if (
      props.accessToken === null ||
      props.accessToken === "" ||
      props.accessToken === undefined
    ) {
      history.push("/");
    }
  }, []);

  return (
    <div className="flex flex-col justify-around my-24 items-center">
      <p
        style={{
          fontWeight: 600,
          fontSize: 28,
          width: "82%",
          marginBottom: "20px",
        }}
      >
        User List
      </p>
      <table class="table" style={{ width: "80%" }}>
        <thead>
          <tr>
            <th scope="col" style={{ background: "rgb(245,245,245)" }}>
              User Name
            </th>
            <th scope="col" style={{ background: "rgb(245,245,245)" }}>
              Trustee
            </th>
          </tr>
        </thead>
        <tbody>
          {[...Array(10)].map((a, i) => {
            return (
              <tr>
                <td style={{ color: "gray" }}>{`Edward King ${i}`}</td>
                <td style={{ color: "gray" }}>{`Trustee ${i}`}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    redirectTo: state.auth.redirectTo,
    invalidUser: state.auth.invalidUser,
    accessToken: state.auth.accessToken,
  };
};

export default connect(mapStateToProps, { auth })(Users);
