import React from "react";
import Header from "../components/Header";

const PrivacyPolicy = () => {
  return (
    <div>
      <Header />
      <div className="settings-page-root-container">
        <h2 className="text-2xl font-bold">Privacy Policy</h2>
        <hr className="mt-6 mb-4 border-yellow-500 border-2" />
        <div>
          <p>
            Privacy Policy are the legal agreements between a service provider
            and a person who wants to use that service. The person must agree to
            abide by the terms of service in order to use the offered service.
            Terms of service can also be merely a disclaimer, especially
            regarding the use of websites. Privacy Policies and Terms and
            Conditions (T&C) agreements are both, as the names imply, legally
            binding contracts. The main difference between these two types of
            agreements is this: A Privacy Policy agreement exists to protect
            your clients. A T&C agreement exists to protect you, the company.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
