import { Link } from "react-router-dom";
import Header from "../components/Header";
import Button from "../components/Button";
import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import { connect, useDispatch } from "react-redux";
import { auth } from "../Store/Actions/Auth";
import toast from 'react-hot-toast';
import "./addclient.css";

const AddClient = (props) => {
  const [addData, setAddData] = useState({});

  console.log(addData);

  const history = useHistory();

  const addClient = (e) => {
    e.preventDefault();

    if (addData.first_name?.length < 5 || addData.last_name?.length < 5) {
      toast("First and last names should be longer than 5 characters")
      return false;
    }
    let data = JSON.stringify(addData);

    var config = {
      method: "post",
      url: "http://www.novasatus.life/api/v1/user/add-client/",
      headers: {
        Authorization: `Token ${props.accessToken}`,
        "content-type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        toast("Client Added Successfully")
        history.push("/clientlist");
      })
      .catch(function (error) {
        console.log(error);
        for (let key in error.response.data) {
          if (error.response.data.hasOwnProperty(key)) {
            return toast(key + ": " + error.response.data[key][0])
          }
        }
      });
  };

  return (
    <div className="client-root-one">
      <Header />
      <div className="client-root-container mt-4">
        <form onSubmit={addClient} className="client-root-container">
          <div>
            <div className="">
              <h1 className="text-2xl font-semibold">Add New Client</h1>
              <hr className="my-3 border-yellow-500 border-2" />
              <div className="pb-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 text-lg">
                <input
                  style={{ background: "#F1F6FB" }}
                  className="pl-2  py-1 mx-8 my-2"
                  value={addData.first_name}
                  placeholder="First Name"
                  required
                  type="text"
                  onChange={(e) => {
                    setAddData({ ...addData, first_name: e.target.value });
                  }}
                />

                <input
                  style={{ background: "#F1F6FB" }}
                  className="bg-gray-200 pl-2  font-medium py-1 mx-8 my-2"
                  value={addData.last_name}
                  placeholder="Last Name"
                  type="text"
                  required
                  onChange={(e) => {
                    setAddData({ ...addData, last_name: e.target.value });
                  }}
                />
                <input
                  style={{ background: "#F1F6FB" }}
                  className="bg-gray-200 pl-2  font-medium py-1 mx-8 my-2"
                  value={addData.email}
                  placeholder="Enter Email"
                  required
                  type="email"
                  onChange={(e) => {
                    setAddData({ ...addData, email: e.target.value });
                  }}
                />
                <input
                  style={{ background: "#F1F6FB" }}
                  className=" pl-2  font-medium py-1 mx-8 my-2"
                  placeholder="State"
                />
                <input
                  style={{ background: "#F1F6FB" }}
                  className="  pl-2  font-medium py-1 mx-8 my-2"
                  placeholder="City"
                />
                <input
                  style={{ background: "#F1F6FB" }}
                  className=" pl-2  font-medium py-1 mx-8 my-2"
                  placeholder="Street"
                />
                <input
                  style={{ background: "#F1F6FB" }}
                  className=" pl-2  font-medium py-1 mx-8 my-2"
                  placeholder="ZIP"
                />
                <input
                  style={{ background: "#F1F6FB" }}
                  className=" pl-2  font-medium py-1 mx-8 my-2"
                  placeholder="Phone Number"
                  type="number"
                  required
                />
                <input
                  style={{ background: "#F1F6FB" }}
                  className="pl-2  font-medium py-1 mx-8 my-2"
                  placeholder="SSN"
                />
              </div>
            </div>
            <div className="">
              <h1 className="text-2xl font-semibold ">Trustee</h1>

              <hr className="my-3 border-yellow-500 border-2" />

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 text-lg">
                <input
                  style={{ background: "#F1F6FB" }}
                  className="pl-2  font-medium py-1 mx-8 my-2"
                  value={addData.trustee_name}
                  placeholder="Name"
                  type="text"
                  // required
                  onChange={(e) => {
                    setAddData({ ...addData, trustee_name: e.target.value });
                  }}
                />
                <input
                  style={{ background: "#F1F6FB" }}
                  className="pl-2 font-medium py-1 mx-8 my-2"
                  value={addData.trustee_address}
                  placeholder="Address"
                  type="text"
                  // required
                  onChange={(e) => {
                    setAddData({ ...addData, trustee_address: e.target.value });
                  }}
                />
                <input
                  style={{ background: "#F1F6FB" }}
                  className=" pl-2 rounded-md font-medium py-1 mx-8 my-2"
                  value={addData.trustee_phone}
                  placeholder="Phone Number"
                  type="number"
                  // required
                  onChange={(e) => {
                    setAddData({ ...addData, trustee_phone: e.target.value });
                  }}
                />
                <input
                  style={{ background: "#F1F6FB" }}
                  className=" pl-2 rounded-md font-medium py-1 mx-8 my-2"
                  value={addData.trustee_email}
                  placeholder="Email"
                  type="email"
                  // required
                  onChange={(e) => {
                    setAddData({ ...addData, trustee_email: e.target.value });
                  }}
                />
              </div>
              <div className="p-4">
                <Button
                  type="submit"
                  buttonStyle="btn--major--solid"
                  buttonSize="btn--medium"
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.accessToken,
  };
};

export default connect(mapStateToProps, { auth })(AddClient);

//export default AddClient;
