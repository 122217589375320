import React, { useState, useEffect } from "react";
import Button from "../components/Button";
import Card from "../components/Card";

import Actions from "../Store/Type";
import { auth } from "../Store/Actions/Auth";
import ConfigureStore from "../Store/Store";
import { useHistory } from "react-router";
import { connect, useDispatch } from "react-redux";
import Header from "../components/Header";
import { userUpdate } from "../Store/Actions/User";

import axios from "axios";

const Nexusloan = (props) => {
  const history = useHistory();
  const [invalid, setInvalid] = useState(false);
  const [user_Details, setUser_Details] = useState([]);

  const [redirect, setRedirect] = useState(false);
  const dispatch = useDispatch();
  const [message, setMessage] = useState(null);
  const [link, setLink] = useState(false);

   const loadScript = (src) => new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) {
      // return resolve()
    }
    const script = document.createElement('script')
    script.src = src
    script.onload = () => resolve()
    script.onerror = (err) => reject(err)
    document.body.appendChild(script)
  })
  
  useEffect(() => {
    //const src = 'https://payitoff-cdn.io/sandbox/nexus/js/v1'
    const src = 'https://payitoff-cdn.io/nexus/js/v1'

    loadScript(src)
      .then(() => {
        /* global Nexus */
        Nexus({
          //nexus_key: '4cf0d535a934aba6575a2db6121dfffd61587dd60f17cc959d1e2c6a0de4d31d',
          nexus_key: '8091f0ca5bbdf8c2f5c7549b1c14bd4c0777a987612449286bbd80e8aeb2225f',
          borrower: props.user + props.last_name + props.id
          //borrower: "charlesdemo1"
        })
        console.log(Nexus)
      })
      .catch(console.error)

    const clientSecret = new URLSearchParams(window.location.search).get('redirect_status');
  
    if (!clientSecret) {
      return;
    }

  }, [])
  

  const onPayItOff = () => {
    Nexus.assess({
      // the function to call when borrower is done
      onSuccess: (response) => {
        // we'll just console.log our response so we can inspect it
        console.log(response)
        onPayment()
        setLink(true)
      },
      // the function to call when borrower quits or error occurs
      onExit: (response) => {
        // we'll just console.log our response so we can inspect it
        console.log(response)
      },
    })
  }

  const onEnroll = () => {
    Nexus.enroll({
      // the function to call when borrower is done
      onSuccess: (response) => {
        // we'll just console.log our response so we can inspect it
        console.log(response)
        //navigation.navigate('HomeStudent')
      },
      // the function to call when borrower quits or error occurs
      onExit: (response) => {
        // we'll just console.log our response so we can inspect it
        console.log(response)
      },
    })
  }

  const onPayment = () => {
    // console.log("Payment time")
   // navigation.navigate('Payments')
   history.push('payment');
  }


  return (
    <div className="root-one">
      <Header />


      <div className="login-root-container rounded-2xl">
        <div className="flex justify-end m-2"></div>
        <h1 className="text-2xl font-semibold">Linking Service Provider</h1>
        <br />
        {/* <p>Description:</p> */}
        <p>
          First of all you need to link your service provider account to become
          eligible for the plans to enroll.
        </p>

        <br />
        <Button
          buttonStyle="btn--major--solid"
          buttonSize="btn--small"
          //onClick={handleLinkNexus}
          onClick={onPayItOff}
        >
          Link
        </Button>
      </div>
      {link &&
      <div className="login-root-container rounded-2xl">
        <h1 className="text-2xl font-semibold">Enroll for the Plan</h1>
        <br />
         <p>Description:</p> 

        <p>
          After Linking the accounts you need to fill some personal details and
          then you will be provided with the plans for which you are eligible.
        </p>
        <br />

        <Button
          className="ml-20"
          buttonStyle="btn--major--solid"
          buttonSize="btn--small"
          onClick={onEnroll}
        >
          Enroll
        </Button>
         &nbsp; &nbsp; 
        {/* <Button
          buttonStyle="btn--major--solid"
          buttonSize="btn--small"
          onClick={handleAssessNexus}
        >
          Assess
        </Button>  */}
      </div>
       }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    redirectTo: state.auth.redirectTo,
    invalidUser: state.auth.invalidUser,
    accessToken: state.auth.accessToken,
    loanContext: state.auth.loanContext,
    borrower_id: state.user_update.borrower_id,

    user: state.auth.user,
    email: state.auth.email,

  };
};

export default connect(mapStateToProps, { auth })(Nexusloan);
