import React from "react";
import Header from "../components/Header";
import PaymentCard from "../components/PaymentCard";

const PaymentPage = () => {
  return (
    <div>
      <PaymentCard />
    </div>
  );
};

export default PaymentPage;
