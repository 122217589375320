import React, {useState, useEffect} from "react";
import {Link, useHistory} from "react-router-dom";
import Button from "../components/Button";
import {connect, useDispatch} from "react-redux";
import reg from "../Store/Actions/Registration";
import Header from "../components/Header";
import "../components/card.css";
import "./LawHomePage.css";
import eye from "../assets/images/eye.svg";
import hide from "../assets/images/hide.svg";
import toast from 'react-hot-toast';
import Actions from "../Store/Type";

const SignupLaw = (props) => {
  const [show, setShow] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const [registration, setRegistration] = useState({
    name: "",
    email: "",
    company_name: "",
    street: "",
    city: "",
    country: "",
    tax_id: "",
    phone_number: "",
    user_type: "law_firm",
    password: "",
    password2: "",
  });

  console.log(registration);

  const history = useHistory();
  const dispatch = useDispatch();

  let Red = props.redirectToLogin;

  useEffect(() => {
    if (props.redirectToLogin) {
      toast("Successfully Signed Up")
      history.push("/login");
    }
  }, [Red, props.redirectToLogin]);

  const handleSignup = (e) => {
    e.preventDefault();
    if (registration.password !== registration.password2) {
      toast("Passwords donot match");
      return;
    }

    //==--== for email
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (regex.test(registration.email) == false) {
      console.log("registration email check ==--== ", regex.test(registration.email));
      toast("Email is not valid")
      return
    }

    reg({
      name: registration.name,
      email: registration.email,
      company_name: registration.company_name,
      street: registration.street,
      city: registration.city,
      country: registration.country,
      tax_id: registration.tax_id,
      phone_number: registration.phone_number,
      first_name: "test",
      last_name: "test",
      user_type: registration.user_type,
      password: registration.password,
      password2: registration.password2,
    })
      .then(function (response) {
        console.log("checking response ==-== ", response);
        dispatch({
          type: Actions.SIGNUP,
          payload: response,
        });
      })
      .catch(function (error) {
        if (error.response.data) {
          for (let key in error.response.data) {
            toast(`${key}: ${error.response.data[key][0]}`)
            return
          }
        } else if (error != null && error != undefined) {
          toast("Signup Failed! \n" + error)
        }
      });
  };

  const styles = {
    iconStyle: {right: 40, top: 10, position: 'absolute', height: '28px'},
    inputPassword: {position: 'relative', width: '112%', display: 'flex',}
  }

  return (
    <div className="signup-root-one">
      <Header/>
      <div className="signup-root-container rounded-2xl">
        <div className="text-center font-semibold text-2xl pb-16">
          <h1>Sign Up As a Law Firm</h1>
        </div>
        <form
          onSubmit={handleSignup}
          // className="grid grid-cols-1 md:grid-cols-2 text-lg"
          className="flex text-lg"
          style={{flexFlow: 'column'}}
        >
          <div className="row">

            <div className="col-md-6 col-lg-6 col-sm-12 flex justify-center items-center" style={{flexFlow: 'column'}}>
              <input
                style={{
                  background: "#F1F6FB", width: '100%',
                  height: '5vh'
                }}
                className="pl-2 rounded-md font-medium py-1 mx-8 my-2"
                placeholder="Attorney Name"
                required
                value={registration.name}
                onChange={(e) =>
                  setRegistration({
                    ...registration,
                    name: e.target.value,
                  })
                }
              />
              <input
                style={{
                  background: "#F1F6FB", width: '100%',
                  height: '5vh'
                }}
                className="pl-2 rounded-md font-medium py-1 mx-8 my-2"
                placeholder="Company Name"
                required
                value={registration.company_name}
                onChange={(e) =>
                  setRegistration({...registration, company_name: e.target.value})
                }
              />
              <input
                style={{
                  background: "#F1F6FB", width: '100%',
                  height: '5vh'
                }}
                className="pl-2 rounded-md font-medium py-1 mx-8 my-2"
                placeholder="Street"
                required
                value={registration.street}
                onChange={(e) =>
                  setRegistration({...registration, street: e.target.value})
                }
              />
              <input
                style={{
                  background: "#F1F6FB", width: '100%',
                  height: '5vh'
                }}
                className="pl-2 rounded-md font-medium py-1 mx-8 my-2"
                placeholder="City"
                required
                value={registration.city}
                onChange={(e) =>
                  setRegistration({...registration, city: e.target.value})
                }
              />
              <input
                style={{
                  background: "#F1F6FB", width: '100%',
                  height: '5vh'
                }}
                className="pl-2 rounded-md font-medium py-1 mx-8 my-2"
                placeholder="Country"
                required
                value={registration.country}
                onChange={(e) =>
                  setRegistration({...registration, country: e.target.value})
                }
              />

            </div>


            <div
              className="col-md-6 col-lg-6 col-sm-12 flex justify-center items-center"
              style={{flexFlow: 'column'}}>
              <input
                style={{
                  background: "#F1F6FB", width: '100%',
                  height: '5vh'
                }}
                className="pl-2 rounded-md font-medium py-1 mx-8 my-2"
                placeholder="Tax ID"
                required
                value={registration.tax_id}
                onChange={(e) =>
                  setRegistration({...registration, tax_id: e.target.value})
                }
              />

              <input
                style={{
                  background: "#F1F6FB", width: '100%',
                  height: '5vh'
                }}
                className="pl-2 rounded-md font-medium py-1 mx-8 my-2"
                placeholder="Phone Number"
                required
                value={registration.phone_number}
                onChange={(e) =>
                  setRegistration({...registration, phone_number: e.target.value})
                }
              />
              <input
                style={{
                  background: "#F1F6FB", width: '100%',
                  height: '5vh'
                }}
                className="pl-2 rounded-md font-medium py-1 mx-8 my-2"
                placeholder="Email"
                required
                value={registration.email}
                onChange={(e) =>
                  setRegistration({...registration, email: e.target.value})
                }
              />
              <div style={styles.inputPassword}>
                <input
                  type={show ? "text" : "password"}
                  style={{
                    background: "#F1F6FB", width: '100%',
                    height: '5vh'
                  }}
                  className="pl-2 rounded-md font-medium py-1 mx-8 my-2"
                  placeholder="Create Password"
                  required
                  value={registration.password}
                  onChange={(e) =>
                    setRegistration({
                      ...registration,
                      password: e.target.value,
                    })
                  }
                />
                {show ? (
                  <img
                    style={styles.iconStyle}
                    src={eye}
                    onClick={() => setShow(false)}
                  />
                ) : (
                  <img
                    src={hide}
                    style={styles.iconStyle}
                    onClick={() => setShow(true)}
                  />
                )}
              </div>
              <div style={styles.inputPassword}>
                <input
                  type={visibility ? "text" : "password"}
                  style={{
                    background: "#F1F6FB", width: '100%',
                    height: '5vh'
                  }}
                  className="pl-2 rounded-md font-medium py-1 mx-8 my-2"
                  placeholder="Confirm Password"
                  required
                  value={registration.password2}
                  onChange={(e) =>
                    setRegistration({
                      ...registration,
                      password2: e.target.value,
                    })
                  }
                />
                {visibility ? (
                  <img
                    src={eye}
                    style={styles.iconStyle}
                    onClick={() => setVisibility(false)}
                  />
                ) : (
                  <img
                    src={hide}
                    style={styles.iconStyle}
                    onClick={() => setVisibility(true)}
                  />
                )}
              </div>
            </div>

            <div className="flex flex-col col-12 justify-center items-center mt-5 mb-5">
              <div className="justify-center bg-vector10 space-y-4">
                {/* <div className="flex flex-col m-10 space-y-4">
              <div className="flex flex-col m-10 space-y-4"> */}
                <Button
                  type="submit"
                  buttonStyle="btn--major--solid"
                  buttonSize="btn--small"
                >
                  Sign up
                </Button>
              </div>
              <div className="flex space-x-1 px-10 justify-center">
                <p className="">By using Nova Satus you agree to our </p>
                <p className="text-color">
                  <Link to="/terms" className="card-links">
                    Terms
                  </Link>
                </p>
              </div>
            </div>
          </div>

        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isValid: state.reg.isValid,
    redirectToLogin: state.reg.redirectToLogin,
  };
};

export default connect(mapStateToProps, {reg})(SignupLaw);
