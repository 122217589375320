import axios from "axios";
import { useState, useEffect } from "react";
import Header from "../components/Header";
import StripePayment from "./StripePayment/StripePayment";

const PaymentStripe = () => {

  useEffect(() => {
    //console.log("Token :::: ", localStorage.getItem("token"));
  }, [])

  return (
    <div className="loginloan-root-one">
      <Header />
      <div style={styles.paymentCard}>
        <div className="grid grid-cols-1">
          <StripePayment />
        </div>
      </div>
    </div>
  );
};

export default PaymentStripe;

const styles = {
  paymentCard: {
    backgroundColor: "#fff",
    padding: 16,
    margin: 20,
    borderRadius: 16,
    marginHorizontal: 50,
  }
}