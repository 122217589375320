import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ScreenProvider } from "./context/ScreenContext";
import { Provider } from "react-redux";
import ConfigureStore from "./Store/Store";
import Routes from "../src/routes/Routes";
import { Toaster } from 'react-hot-toast';

const App = () => {
  //build comment

  const { store } = ConfigureStore();
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ScreenProvider>
          <Routes />
          <Toaster />
        </ScreenProvider>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
