import { Link } from "react-router-dom";
import Button from "./Button";
import "./paymentcard.css";
import { useHistory } from "react-router";

import apple from "../assets/images/apple.svg";
import google from "../assets/images/google.svg";
import paypal from "../assets/images/paypal.svg";
import visa from "../assets/images/visa.svg";
import mastercard from "../assets/images/mastercard.svg";
import Header from "./Header";

const PaymentCard = () => {
  const history = useHistory();
  return (
    <div className="paymentcard-root-one">
      <Header />
      <div className="paymentcard-root-container rounded-2xl">
        <h3 className="text-center text-2xl  p-3">Payment</h3>

        <p className="flex justify-center text-gray-600">
          Proceed to Stripe to complete your <br />
          payments with your choice of methods
        </p>

        <div className="mt-6 mb-6 flex justify-center">
          <Button onClick={() => history.push("/paymentstripe")}>
            Proceed to Stripe
          </Button>
        </div>
        <div className="flex items-center justify-center space-x-6">
          <img src={apple} className=" w-7 h-7" />
          <img src={google} className=" w-7 h-7" />
          <img src={paypal} className=" w-7 h-7" />
          <img src={visa} className=" w-7 h-7" />
          <img src={mastercard} className=" w-7 h-7" />
        </div>
      </div>
    </div>
  );
};

export default PaymentCard;
