import React from "react";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  mainPag: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },

  pagination: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "0px",
  },

  pageItem: {
    marginLeft: "20px",
  },

  pageBtn: {
    width: "30px",
    height: "30px",
    background: "#FFFFFF",
    border: "none",
    color: "black",
    cursor: "pointer",
    borderRadius: "50px",
  },
  Previous: {
    textTransform: "capitalize",
    "& .MuiButton-label": {
      fontWeight: 500,
    },
  },
  Next: {
    textTransform: "capitalize",
    color: "#C1123D !important",
    "& .MuiButton-label": {
      fontWeight: 500,
    },
  },
}));
