import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { auth } from "../Store/Actions/Auth";
import Button from "../components/Button";
import Actions from "../Store/Type";
import Card from "../components/Card";
import Header from "../components/Header";
import cv1 from "../assets/images/cv 1.png";
import cv2 from "../assets/images/personal-data2.png";

const StudentHomePage = ({ accessToken, redirectTo }) => {
  const [redirect, setRedirect] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setRedirect(redirectTo);
    if (redirect) {
      history.push(redirectTo);
    }
  }, [redirectTo, redirect]);

  useEffect(() => {
    if (!accessToken) {
      history.push("/");
    }
  }, [accessToken]);

  // const logout = () => {
  //   dispatch({
  //     type: Actions.LOGOUT,
  //   });
  // };

  return (
    <div className="root-one">
      <Header />
      <div className="law-root-container">
        <div className="flex justify-end m-2">
          {/* <Button
            buttonStyle="btn--major--solid"
            buttonSize="btn--small"
            onClick={logout}
          >
            LOGOUT
          </Button> */}
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 items-center">
          <div className="links">
            <Card
              imgsrc={cv1}
              label="Login Loan Database"
              description="Add your clients to collect all information together"
              buttonLabel="Login"
              link="/loginloan"
              isSettings={false}
            />
          </div>

          <div className="links">
            <Card
              imgsrc={cv2}
              label="Settings"
              description="Add your clients to collect all information together"
              buttonLabel="View"
              link="/setting"
              isSettings={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    redirectTo: state.auth.redirectTo,
    accessToken: state.auth.accessToken,
  };
};

export default connect(mapStateToProps, { auth })(StudentHomePage);
