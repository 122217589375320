import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import AuthReducer from "./Reducers/AuthReducer";
import RegReducer from "./Reducers/RegReducer";
import userUpdateReducer from "./Reducers/userUpdateReducer";

const ConfigureStore = () => {
  const rootReducer = combineReducers({
    user_update: userUpdateReducer,
    auth: AuthReducer,
    reg: RegReducer,
  });

  const store = createStore(rootReducer, applyMiddleware(thunk));

  return { store };
};

export default ConfigureStore;
