import axios from "axios";
import Actions from "../Type";
import toast from 'react-hot-toast';

export function auth(userObject) {
  return async (dispatch, getState) => {
    const token = getState().auth.accessToken;

    var data = JSON.stringify(userObject);

    // console.log(data);

    var config = {
      method: "post",
      url: "http://www.novasatus.life/rest-auth/login/",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken":
          "ury9FVgfAgG9op3vdFkjKPmB0Y81dccVqLZ9PAvEKrmFf0I8yZjxjF6UgJx6kVJA",
      },

      data: data,
    };

    // console.log(config);

    await axios(config)
      .then(function (response) {
        console.log(response);

        dispatch({
          type: Actions.LOGIN_SUCCESS,
          payload: response.data,
        });
      })
      .catch(function (error) {
        // console.log("Error while logging in ==--== ", error.response.data.non_field_errors[0]);
        if (
          error.response.data.non_field_errors[0] != null &&
          error.response.data.non_field_errors[0] != undefined
        ) {
          toast("LogIn Failed \n" + error.response.data.non_field_errors[0]);
        }
        dispatch({
          type: Actions.LOGIN_FAIL,
        });
      });
  };
}
