import React, { useContext } from "react";
import rectangle from "../assets/images/Rectangle 1789.png";
import logo from "../assets/images/Nova Satus Logo1.png";
import vector9 from "../assets/images/Vector 9.png";
import vector10 from "../assets/images/Vector 10.png";
import OffcanvasMenu from "react-offcanvas-menu-component";
import { ScreenContext, ScreenProvider } from "../context/ScreenContext";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { auth } from "../Store/Actions/Auth";
import { useDispatch } from "react-redux";
import "./Header.css";
import Button from "./Button";
import Actions from "../Store/Type";

const Header = (props) => {

  const dispatch = useDispatch();
  const { screenSize, setScreenSize } = useContext(ScreenContext);
  console.log(screenSize);

  const logout = () => {
    dispatch({
      type: Actions.LOGOUT,
    });
  };

  const NewAdminHeader = () => {
    return (
      <div className="sticky top-0">
        {screenSize > 800 ? (
          <>
            <div className="student-header">
              <div className="student-header-inner1" style={{ width: "20%" }}>
                <Link
                  to="/lawfirms"
                  style={{ fontWeight: 600, color: "white", cursor: "pointer" }}
                >
                  LAW FIRMS
                </Link>
              </div>
              <div className="student-header-inner2" style={{ width: "20%" }}>
                <Link
                  to="/users"
                  style={{ fontWeight: 600, color: "white", cursor: "pointer" }}
                >
                  USERS
                </Link>
              </div>

              <Link to="/lawhomepage">
                <img src={logo} alt="logo" />
              </Link>

              <div className="student-header-inner2" style={{ width: "20%" }}>
                <Link
                  to="/accounts"
                  style={{ fontWeight: 600, color: "white", cursor: "pointer" }}
                >
                  ACCOUNTS
                </Link>
              </div>

              <div className="student-header-inner2" style={{ width: "20%" }}>
                <Link
                  to="/subscriberList"
                  style={{ fontWeight: 600, color: "white", cursor: "pointer" }}
                >
                  SUBSCRIPTION
                </Link>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="student-header">
              <Link to="/studenthomepage">
                <img src={logo} alt="logo" />
              </Link>
            </div>
            <OffcanvasMenu
              Link={Link} // react-router-dom Link
              location={props.location} // location parameter passed from Router
              config={{
                width: 300, // you can modify default width
                push: true, // if you want to enable push feature
              }}
              menu={[
                { text: "LAW FIRMS", link: "/lawfirms" },
                { text: "USERS", link: "/users" },
                { text: "ACCOUNTS", link: "/accounts" },
                { text: "SUBSCRIPTION", link: "/subscriberList" },
              ]}
              header={
                <img
                  src={logo}
                  className="menu-logo"
                  alt="logo"
                  width={"80"}
                  height="80"
                />
              }
            />
          </>
        )}
      </div>
    );
  };

  const NewStudentHeader = () => {
    return (
      <div className="sticky top-0">
        {screenSize > 800 ? (
          <>
            <div className="student-header">
              <div className="student-header-inner1">
                {props.redirectTo === "/nexusloan" ? null : (
                  <Link
                    to="/nexusloan"
                    style={{
                      fontWeight: 600,
                      color: "white",
                      cursor: "pointer",
                    }}
                  >
                    LOGIN LOAN DATABASE
                  </Link>
                )}
              </div>

              {props.redirectTo === "/nexusloan" ? (
                <img src={logo} alt="logo" />
              ) : (
                <Link to="/studenthomepage">
                  <img src={logo} alt="logo" />
                </Link>
              )}

              <div className="student-header-inner2">
                <Link
                  to="/setting"
                  style={{ fontWeight: 600, color: "white", cursor: "pointer" }}
                >
                  SETTINGS
                </Link>
                <div className="flex justify-end m-4">
                  <Button
                    buttonStyle="btn--major--solid"
                    buttonSize="btn--small"
                    onClick={logout}
                  >
                    LOGOUT
                  </Button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="student-header">
              <Link to="/studenthomepage">
                <img src={logo} alt="logo" />
              </Link>
            </div>
            <OffcanvasMenu
              Link={Link} // react-router-dom Link
              location={props.location} // location parameter passed from Router
              config={{
                width: 150, // you can modify default width
                push: true, // if you want to enable push feature
              }}
              menu={[
                { text: "Login Loan database", link: "/nexusloan" },
                { text: "Settings", link: "/setting" },
              ]}
              header={
                <img
                  src={logo}
                  className="menu-logo ml-8"
                  alt="logo"
                  width="100"
                  height="80"
                />
              }
            />
          </>
        )}
      </div>
    );
  };

  const NewLawHeader = () => {
    return (
      <div className="sticky top-0">
        {screenSize > 800 ? (
          <>
            <div className="student-header">
              <div className="student-header-inner1" style={{ width: "20%" }}>
                <Link
                  to="/clientList"
                  style={{ fontWeight: 600, color: "white", cursor: "pointer" }}
                >
                  CLIENTS
                </Link>
              </div>
              <div className="student-header-inner2" style={{ width: "20%" }}>
                {props.redirectTo === "/nexusloan" ? null : (
                  <Link
                    to="/nexusloan"
                    style={{
                      fontWeight: 600,
                      color: "white",
                      cursor: "pointer",
                    }}
                  >
                    LOGIN LOAN DATABASE
                  </Link>
                )}
              </div>

              <Link to="/lawhomepage">
                <img src={logo} alt="logo" />
              </Link>

              <div className="student-header-inner2" style={{ width: "20%" }}>
                <Link
                  to="/savedforms"
                  style={{ fontWeight: 600, color: "white", cursor: "pointer" }}
                >
                  SAVED FORMS
                </Link>
              </div>

              <div className="student-header-inner2" style={{ width: "20%" }}>
                <Link
                  to="/setting"
                  style={{ fontWeight: 600, color: "white", cursor: "pointer" }}
                >
                  SETTINGS
                </Link>
                <div className="flex justify-end m-4">
                  <Button
                    buttonStyle="btn--major--solid"
                    buttonSize="btn--small"
                    onClick={logout}
                  >
                    LOGOUT
                  </Button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="student-header">
              <Link to="/lawhomepage">
                <img src={logo} alt="logo" />
              </Link>
            </div>
            <OffcanvasMenu
              Link={Link} // react-router-dom Link
              location={props.location} // location parameter passed from Router
              config={{
                width: 300, // you can modify default width
                push: true, // if you want to enable push feature
              }}
              menu={[
                { text: "Clients", link: "/clientlist" },
                { text: "Login Loan database", link: "/nexusloan" },
                { text: "Saved forms", link: "/savedforms" },
                { text: "Settings", link: "/setting" },
                { text: "Logout", link: "/logout" },
              ]}
              header={
                <img
                  src={logo}
                  className="menu-logo"
                  alt="logo"
                  width="80"
                  height="80"
                />
              }
            />
          </>
        )}
      </div>
    );
  };

  const DefaultHeader = () => {
    return (
      <div className="sticky top-0">
        <div className="student-header">
          <Link to="/studenthomepage">
            <img src={logo} alt="logo" />
          </Link>
        </div>
      </div>
    );
  };

  return (
    <>
      {/*Yahan pe tamam headers aaeinge */}
      {props.isType === "admin"
        ? NewAdminHeader()
        : props.isType === "student"
          ? NewStudentHeader()
          : props.isType === "law_firm"
            //? NewLawHeader()
            ? NewStudentHeader()
            : DefaultHeader()}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    redirectTo: state.auth.redirectTo,
    invalidUser: state.auth.invalidUser,
    accessToken: state.auth.accessToken,
    isType: state.auth.isType,
  };
};

export default connect(mapStateToProps, { auth })(Header);
