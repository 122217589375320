import { useState, useEffect } from "react";
import { useLocation } from "react-router";
import Header from "../components/Header";
import "./clientaccount.css";

const ClientAccountInfo = () => {
  const [editBasicInfo, setEditBasicInfo] = useState(false)
  const [editTrusteInfo, setEditTrusteInfo] = useState(false)

  const [clientInfo, setClientInfo] = useState({
    client_first: "",
    client_address: "",
    client_contact: "",
    trustee_name: "",
    trustee_address: "",
    trustee_contact: "",
  })

  const client_first = `${new URLSearchParams(useLocation().search).get(
    "client_first"
  )}`;
  const client_last = `${new URLSearchParams(useLocation().search).get(
    "client_last"
  )}`;
  const client_city = `${new URLSearchParams(useLocation().search).get(
    "client_city"
  )}`;
  const client_state = `${new URLSearchParams(useLocation().search).get(
    "client_state"
  )}`;
  const client_street = `${new URLSearchParams(useLocation().search).get(
    "client_street"
  )}`;
  const client_postal = `${new URLSearchParams(useLocation().search).get(
    "client_postal"
  )}`;
  const client_country = `${new URLSearchParams(useLocation().search).get(
    "client_country"
  )}`;
  const client_email = `${new URLSearchParams(useLocation().search).get(
    "client_email"
  )}`;
  const client_phone = `${new URLSearchParams(useLocation().search).get(
    "client_phone"
  )}`;
  const trustee_name = `${new URLSearchParams(useLocation().search).get(
    "trustee_name"
  )}`;
  const trustee_street = `${new URLSearchParams(useLocation().search).get(
    "trustee_street"
  )}`;
  const trustee_city = `${new URLSearchParams(useLocation().search).get(
    "trustee_city"
  )}`;
  const trustee_state = `${new URLSearchParams(useLocation().search).get(
    "trustee_state"
  )}`;
  const trustee_email = `${new URLSearchParams(useLocation().search).get(
    "trustee_email"
  )}`;
  const trustee_postal = `${new URLSearchParams(useLocation().search).get(
    "trustee_postal"
  )}`;
  const trustee_phone = `${new URLSearchParams(useLocation().search).get(
    "trustee_phone"
  )}`;
  const trustee_address = `${new URLSearchParams(useLocation().search).get(
    "trustee_add"
  )}`;

  const handleBasicInfo = () => {
    setEditBasicInfo(!editBasicInfo)
  }

  const handleTrusteInfo = () => {
    setEditTrusteInfo(!editTrusteInfo)
  }

  // useEffect(() => {
  //   console.log("client info updated ==--== ", clientInfo);
  // }, [clientInfo])

  // const styles = {
  //   container: {
  //   }
  // }

  return (
    <div className="client-root-one">
      <Header />
      <div className="client-root-container">
        <div className="p-5">
          <h1 className="text-2xl font-semibold">Client Name</h1>
          <hr className="my-3 border-yellow-600" />
          <div className="font-semibold flex space-x-4 items-center pb-3">
            <h3 className="text-xl">Basic Information</h3>
            <button onClick={handleBasicInfo} className="underline text-gray-400">edit</button>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 text-lg">
            <div>
              <h1 className="text-gray-400 pb-2">Name</h1>
              {editBasicInfo && <input
                style={{ background: "#F1F6FB" }}
                className="pl-2  py-1 mx-0 my-2"
                // value={client_first}
                placeholder="Name"
                required
                type="text"
                onChange={(e) => {
                  setClientInfo({ ...clientInfo, client_first: e.target.value });
                }}
              />}
              <p>{client_first}</p>

            </div>
            <div>
              <h1 className="text-gray-400 pb-2">Address</h1>
              {editBasicInfo && <input
                style={{ background: "#F1F6FB" }}
                className="bg-gray-200 pl-2  font-medium py-1 mx-0 my-2"
                // value={client_street + " " + client_city + " " + client_state + " " + client_country}
                placeholder="Address"
                type="text"
                required
                onChange={(e) => {
                  setClientInfo({ ...clientInfo, client_address: e.target.value });
                }}
              />}
              <p>{client_street},</p>
              <p>
                {client_city}, {client_state}
              </p>
              <p>{client_country}</p>
              <p>{client_postal}</p>


            </div>
            <div>
              <h1 className="text-gray-400 pb-2">Contact</h1>
              {editBasicInfo && <input
                style={{ background: "#F1F6FB" }}
                className="bg-gray-200 font-medium pl-2  py-1 mx-0 my-2"
                // value={client_email}
                placeholder="Contact"
                required
                type="text"
                onChange={(e) => {
                  setClientInfo({ ...clientInfo, client_contact: e.target.value });
                }}
              />}
              <p>{client_email}</p>
              <p>{client_phone}</p>

            </div>
          </div>
          {/* ==--== */}




          <hr className="my-3 border-yellow-600" />
          <div className="font-semibold flex space-x-4 items-center pb-3">
            <h3 className="text-xl">Trustee Information</h3>
            <button onClick={handleTrusteInfo} className="underline text-gray-400">edit</button>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 text-lg">
            <div>
              <h1 className="text-gray-400 pb-2">Trustee</h1>
              {editTrusteInfo && <input
                style={{ background: "#F1F6FB" }}
                className="pl-2  py-1 mx-0 my-2"
                // value={trustee_name}
                placeholder="Trustee Name"
                required
                type="text"
                onChange={(e) => {
                  setClientInfo({ ...clientInfo, trustee_name: e.target.value });
                }}
              />}
              <p>{trustee_name}</p>
            </div>
            <div>
              <h1 className="text-gray-400 pb-2">Address</h1>
              {editTrusteInfo && <input
                style={{ background: "#F1F6FB" }}
                className="pl-2  py-1 mx-0 my-2"
                // value={trustee_address}
                placeholder="Trustee Address"
                required
                type="text"
                onChange={(e) => {
                  setClientInfo({ ...clientInfo, trustee_address: e.target.value });
                }}
              />}
              <p>{trustee_address},</p>
              <p>
                {trustee_city ?? "City"}, {trustee_state ?? "State"}
              </p>
              {/* <p>{trustee_country}</p> */}
              <p>{trustee_postal}</p>
            </div>
            <div>
              <h1 className="text-gray-400 pb-2">Contact</h1>
              {editTrusteInfo && <input
                style={{ background: "#F1F6FB" }}
                className="pl-2  py-1 mx-0 my-2"
                // value={trustee_email}
                placeholder="Trustee Contact"
                required
                type="text"
                onChange={(e) => {
                  setClientInfo({ ...clientInfo, trustee_contact: e.target.value });
                }}
              />}
              <p>{trustee_email}</p>
              <p>{trustee_phone}</p>
            </div>
          </div>
          <hr className="my-3 border-yellow-600" />
          <div className="font-semibold flex space-x-4 items-center pb-3">
            <h3 className="text-xl">Other Information</h3>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 text-lg">
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientAccountInfo;
