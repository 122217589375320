import Card from "../components/Card";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { connect, useDispatch } from "react-redux";
import Header from "../components/Header";
import "./LawHomePage.css";
import cv1 from "../assets/images/cv 1.png";
import file from "../assets/images/file.png";
import file2 from "../assets/images/personal-data2.png";
import cv2 from "../assets/images/job-interview 1.png";
import { auth } from "../Store/Actions/Auth";

const LawHomePage = ({ accessToken, redirectTo }) => {
  const [redirect, setRedirect] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setRedirect(redirectTo);
    if (redirect) {
      history.push(redirectTo);
    }
  }, [redirectTo, redirect]);

  useEffect(() => {
    if (!accessToken) {
      history.push("/");
    }
  }, [accessToken]);

  return (
    <div className="root-one">
      <Header />
      <div className="law-root-container">
        <div className="flex justify-end m-2">
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 items-center">
          <div className="links">
            <Card
              imgsrc={cv2}
              label="Clients"
              description="Add your clients to collect all information together"
              buttonLabel="Add New"
              link="/addclient"
              link2="/clientlist"
              doubleButtons={true}
            />
          </div>
          <div className="links">
            <Card
              imgsrc={cv1}
              label="Login Loan Database"
              buttonLabel="LOGIN"
              description="Add your clients to collect all information together"
              link="/nexusloan"
            />
          </div>
          <div className="links">
            <Card
              imgsrc={file}
              label="Saved Forms"
              description="Add your clients to collect all information together"
              buttonLabel="Add New"
              link="/savedforms"
              isSettings={true}
            />
          </div>
          <div className="links">
            <Card
              imgsrc={file2}
              label="Settings"
              description="Add your clients to collect all information together"
              buttonLabel="View"
              link="/setting"
              isSettings={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    redirectTo: state.auth.redirectTo,
    accessToken: state.auth.accessToken,
  };
};

export default connect(mapStateToProps, { auth })(LawHomePage);
