import React, { useEffect, useState, useContext } from "react";
import { TypeContext } from "../context/TypeContext";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import { auth } from "../Store/Actions/Auth";

const SubscriberList = (props) => {
  const history = useHistory();

  useEffect(() => {
    if (
      props.accessToken === null ||
      props.accessToken === "" ||
      props.accessToken === undefined
    ) {
      history.push("/");
    }
  }, []);

  return (
    <div className="flex flex-col justify-around my-24 items-center  max-w-7xl mx-auto px-8 sm:px-20">
      <p
        style={{
          fontWeight: 600,
          fontSize: 22,
          width: "88%",
          marginBottom: "50px",
        }}
      >
        SUBSCRIPTIONS
      </p>
      <div className="flex justify-around w-full pb-10">
        <div className="flex-col">
          <h2 className="md:text-6xl text-3xl font-bold">532</h2>
          <p style={{ marginTop: "10px", color: "gray" }}>TOTAL SUBSCRIBERS</p>
        </div>
        <div className="flex-col">
          <h2 className=" md:text-6xl text-3xl font-bold">$10,000</h2>
          <p className="text-left" style={{ marginTop: "10px", color: "gray" }}>
            MONTHLY RECURRING
          </p>
        </div>
        <div className="flex-col">
          <h2 className=" md:text-6xl text-3xl font-bold">$1,156,532</h2>
          <p style={{ marginTop: "10px", color: "gray" }}>
            TOTAL AMOUNT EARNED
          </p>
        </div>
      </div>
      <table class="table" style={{ width: "90%" }}>
        <thead>
          <tr>
            <th scope="col" style={{ background: "rgb(245,245,245)" }}>
              Subscriber
            </th>
            <th scope="col" style={{ background: "rgb(245,245,245)" }}>
              Subscriptions Type
            </th>
            <th scope="col" style={{ background: "rgb(245,245,245)" }}>
              Total Earned
            </th>
          </tr>
        </thead>
        <tbody>
          {[...Array(10)].map((a, i) => {
            return (
              <tr>
                <td style={{ color: "gray" }}>{`Edward King ${i}`}</td>
                <td style={{ color: "gray" }}>{`Monthly`}</td>
                <td style={{ color: "gray" }}>{`$50,000`}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    redirectTo: state.auth.redirectTo,
    invalidUser: state.auth.invalidUser,
    accessToken: state.auth.accessToken,
  };
};

export default connect(mapStateToProps, { auth })(SubscriberList);
