import React from 'react';
import * as Loader from 'react-loader-spinner';

export default function ScreenLoader() {
     return (
          <div
               style={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    top: '40%',
                    left: '48%',
                    opacity: 0.75,
                    zIndex: 3,
               }}
          >
               <Loader.ThreeDots
                    style={{ position: 'fixed', margin: 'auto' }}
                    type="ThreeDots"
                    color="#e94322"
                    height="100px"
                    width="100px"
               />
          </div>
     );
}
