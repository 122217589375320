import React, { useState, useEffect, createContext } from "react";

export const ScreenContext = createContext();

export const ScreenProvider = (props) => {
  //default value admin
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  useEffect(() => {
    setScreenSize(window.innerWidth);
  }, [screenSize]);

  const screenChange = () => {
    setScreenSize(window.innerWidth);
  };

  window.addEventListener("resize", screenChange);
  return (
    <ScreenContext.Provider value={{ screenSize, setScreenSize }}>
      {props.children}
    </ScreenContext.Provider>
  );
};
