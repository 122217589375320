import React from "react";
import Header from "../components/Header";

const TermsOfService = () => {
  return (
    <div>
      <Header />
      <div className="settings-page-root-container">
        <h2 className="text-2xl font-bold">Terms and Conditions</h2>
        <hr className="mt-6 mb-4 border-yellow-500 border-2" />
        <div style={{ width: "100%", height: "100vh" }}>
          <div>
            <p
              className="text-base "
              style={{ lineHeight: 2, marginBottom: 20, color: "gray" }}
            >
              These terms and conditions (“Agreement”) set forth the general
              terms and conditions of your use of the{" "}
              <a
                href="http://www.novasatus.life"
                target="_blank"
                class="text-blue-600 visited:text-purple-600 ..."
              >
                NovaSatus.Life
              </a>{" "}
              website (“Website”), “Nova Satus” mobile application (“Mobile
              Application”) and any of their related products and services
              (collectively, “Services”). This Agreement is legally binding
              between you (“User”, “you” or “your”) and Nova Satus (“Nova
              Satus”, “we”, “us” or “our”). If you are entering into this
              Agreement on behalf of a business or other legal entity, you
              represent that you have the authority to bind such entity to this
              Agreement, in which case the terms “User”, “you” or “your” shall
              refer to such entity. If you do not have such authority, or if you
              do not agree with the terms of this Agreement, you must not accept
              this Agreement and may not access and use the Services. By
              accessing and using the Services, you acknowledge that you have
              read, understood, and agree to be bound by the terms of this
              Agreement. You acknowledge that this Agreement is a contract
              between you and Nova Satus, even though it is electronic and is
              not physically signed by you, and it governs your use of the
              Services.
            </p>
            <h2 className="text-xl font-bold" style={{ marginBottom: 20 }}>
              Table of Contents
            </h2>
            <ul style={{ marginBottom: 20 }}>
              <li>
                <a
                  href="#accounts-and-membership"
                  class="text-blue-600 visited:text-purple-600 ..."
                >
                  1. Accounts and membership
                </a>
              </li>
              <li>
                <a
                  href="#billing-and-payments"
                  class="text-blue-600 visited:text-purple-600 ..."
                >
                  2. Billing and payments
                </a>
              </li>
              <li>
                <a
                  href="#accuracy-of-information"
                  class="text-blue-600 visited:text-purple-600 ..."
                >
                  3. Accuracy of information
                </a>
              </li>
              <li>
                <a
                  href="#links-to-other-resources"
                  class="text-blue-600 visited:text-purple-600 ..."
                >
                  4. Links to other resources
                </a>
              </li>
              <li>
                <a
                  href="#intellectual-property-rights"
                  class="text-blue-600 visited:text-purple-600 ..."
                >
                  5. Intellectual property rights
                </a>
              </li>
              <li>
                <a
                  href="#limitation-of-liability"
                  class="text-blue-600 visited:text-purple-600 ..."
                >
                  6. Limitation of liability
                </a>
              </li>
              <li>
                <a
                  href="#changes-and-amendments"
                  class="text-blue-600 visited:text-purple-600 ..."
                >
                  7. Changes and amendments
                </a>
              </li>
              <li>
                <a
                  href="#acceptance-of-these-terms"
                  class="text-blue-600 visited:text-purple-600 ..."
                >
                  8. Acceptance of these terms
                </a>
              </li>
              <li>
                <a
                  href="#contacting-us"
                  class="text-blue-600 visited:text-purple-600 ..."
                >
                  9. Contacting us
                </a>
              </li>
            </ul>
            <h3
              id="accounts-and-membership"
              className="text-xl font-bold"
              style={{ marginBottom: 20 }}
            >
              1. Accounts and membership
            </h3>
            <p
              className="text-base "
              style={{ lineHeight: 2, marginBottom: 20, color: "gray" }}
            >
              You must be at least 18 years of age to use the Services. By using
              the Services and by agreeing to this Agreement you warrant and
              represent that you are at least 18 years of age. If you create an
              account on the Services, you are responsible for maintaining the
              security of your account and you are fully responsible for all
              activities that occur under the account and any other actions
              taken in connection with it. We may, but have no obligation to,
              monitor and review new accounts before you may sign in and start
              using the Services. Providing false contact information of any
              kind may result in the termination of your account. You must
              immediately notify us of any unauthorized uses of your account or
              any other breaches of security. We will not be liable for any acts
              or omissions by you, including any damages of any kind incurred as
              a result of such acts or omissions. We may suspend, disable, or
              delete your account (or any part thereof) if we determine that you
              have violated any provision of this Agreement or that your conduct
              or content would tend to damage our reputation and goodwill. If we
              delete your account for the foregoing reasons, you may not
              re-register for our Services. We may block your email address and
              Internet protocol address to prevent further registration.
            </p>

            <h3
              id="billing-and-payments"
              className="text-xl font-bold"
              style={{ marginBottom: 20 }}
            >
              2. Billing and payments
            </h3>
            <p
              className="text-base "
              style={{ lineHeight: 2, marginBottom: 20, color: "gray" }}
            >
              You shall pay all fees or charges to your account in accordance
              with the fees, charges, and billing terms in effect at the time a
              fee or charge is due and payable. If, in our judgment, your
              purchase constitutes a high-risk transaction, we will require you
              to provide us with a copy of your valid government-issued photo
              identification, and possibly a copy of a recent bank statement for
              the credit or debit card used for the purchase. We reserve the
              right to change products and product pricing at any time. We also
              reserve the right to refuse any order you place with us. We may,
              in our sole discretion, limit or cancel quantities purchased per
              person, per household or per order. These restrictions may include
              orders placed by or under the same customer account, the same
              credit card, and/or orders that use the same billing and/or
              shipping address. In the event that we make a change to or cancel
              an order, we may attempt to notify you by contacting the e-mail
              and/or billing address/phone number provided at the time the order
              was made.
            </p>

            <h3
              id="accuracy-of-information"
              className="text-xl font-bold"
              style={{ marginBottom: 20 }}
            >
              3. Accuracy of information
            </h3>
            <p
              className="text-base "
              style={{ lineHeight: 2, marginBottom: 20, color: "gray" }}
            >
              Occasionally there may be information on the Services that
              contains typographical errors, inaccuracies or omissions that may
              relate to availability, promotions and offers. We reserve the
              right to correct any errors, inaccuracies or omissions, and to
              change or update information or cancel orders if any information
              on the Services or Services is inaccurate at any time without
              prior notice (including after you have submitted your order). We
              undertake no obligation to update, amend or clarify information on
              the Services including, without limitation, pricing information,
              except as required by law. No specified update or refresh date
              applied on the Services should be taken to indicate that all
              information on the Services or Services has been modified or
              updated.
            </p>

            <h3
              id="links-to-other-resources"
              className="text-xl font-bold"
              style={{ marginBottom: 20 }}
            >
              4. Links to other resources
            </h3>
            <p
              className="text-base "
              style={{ lineHeight: 2, marginBottom: 20, color: "gray" }}
            >
              Although the Services may link to other resources (such as
              websites, mobile applications, etc.), we are not, directly or
              indirectly, implying any approval, association, sponsorship,
              endorsement, or affiliation with any linked resource, unless
              specifically stated herein. We are not responsible for examining
              or evaluating, and we do not warrant the offerings of, any
              businesses or individuals or the content of their resources. We do
              not assume any responsibility or liability for the actions,
              products, services, and content of any other third parties. You
              should carefully review the legal statements and other conditions
              of use of any resource which you access through a link on the
              Services. Your linking to any other off-site resources is at your
              own risk.
            </p>

            <h3
              id="intellectual-property-rights"
              className="text-xl font-bold"
              style={{ marginBottom: 20 }}
            >
              5. Intellectual property rights
            </h3>
            <p
              className="text-base "
              style={{ lineHeight: 2, marginBottom: 20, color: "gray" }}
            >
              “Intellectual Property Rights” means all present and future rights
              conferred by statute, common law or equity in or in relation to
              any copyright and related rights, trademarks, designs, patents,
              inventions, goodwill and the right to sue for passing off, rights
              to inventions, rights to use, and all other intellectual property
              rights, in each case whether registered or unregistered and
              including all applications and rights to apply for and be granted,
              rights to claim priority from, such rights and all similar or
              equivalent rights or forms of protection and any other results of
              intellectual activity which subsist or will subsist now or in the
              future in any part of the world. This Agreement does not transfer
              to you any intellectual property owned by Nova Satus or third
              parties, and all rights, titles, and interests in and to such
              property will remain (as between the parties) solely with Nova
              Satus. All trademarks, service marks, graphics and logos used in
              connection with the Services, are trademarks or registered
              trademarks of Nova Satus or its licensors. Other trademarks,
              service marks, graphics and logos used in connection with the
              Services may be the trademarks of other third parties. Your use of
              the Services grants you no right or license to reproduce or
              otherwise use any of Nova Satus or third party trademarks.
            </p>

            <h3
              id="limitation-of-liability"
              className="text-xl font-bold"
              style={{ marginBottom: 20 }}
            >
              6. Limitation of liability
            </h3>
            <p
              className="text-base "
              style={{ lineHeight: 2, marginBottom: 20, color: "gray" }}
            >
              To the fullest extent permitted by applicable law, in no event
              will Nova Satus, its affiliates, directors, officers, employees,
              agents, suppliers or licensors be liable to any person for any
              indirect, incidental, special, punitive, cover or consequential
              damages (including, without limitation, damages for lost profits,
              revenue, sales, goodwill, use of content, impact on business,
              business interruption, loss of anticipated savings, loss of
              business opportunity) however caused, under any theory of
              liability, including, without limitation, contract, tort,
              warranty, breach of statutory duty, negligence or otherwise, even
              if the liable party has been advised as to the possibility of such
              damages or could have foreseen such damages. To the maximum extent
              permitted by applicable law, the aggregate liability of Nova Satus
              and its affiliates, officers, employees, agents, suppliers and
              licensors relating to the services will be limited to an amount no
              greater than one dollar or any amounts actually paid in cash by
              you to Nova Satus for the prior one month period prior to the
              first event or occurrence giving rise to such liability. The
              limitations and exclusions also apply if this remedy does not
              fully compensate you for any losses or fails of its essential
              purpose.
            </p>

            <h3
              id="changes-and-amendments"
              className="text-xl font-bold"
              style={{ marginBottom: 20 }}
            >
              7. Changes and amendments
            </h3>
            <p
              className="text-base "
              style={{ lineHeight: 2, marginBottom: 20, color: "gray" }}
            >
              We reserve the right to modify this Agreement or its terms related
              to the Services at any time at our discretion. When we do, we will
              post a notification within the Services. We may also provide
              notice to you in other ways at our discretion, such as through the
              contact information you have provided.
              <br />
              <br />
              An updated version of this Agreement will be effective immediately
              upon the posting of the revised Agreement unless otherwise
              specified. Your continued use of the Services after the effective
              date of the revised Agreement (or such other act specified at that
              time) will constitute your consent to those changes.
            </p>

            <h3
              id="acceptance-of-these-terms"
              className="text-xl font-bold"
              style={{ marginBottom: 20 }}
            >
              8. Acceptance of these terms
            </h3>
            <p
              className="text-base "
              style={{ lineHeight: 2, marginBottom: 20, color: "gray" }}
            >
              You acknowledge that you have read this Agreement and agree to all
              its terms and conditions. By accessing and using the Services you
              agree to be bound by this Agreement. If you do not agree to abide
              by the terms of this Agreement, you are not authorized to access
              or use the Services.
            </p>

            <h3
              id="contacting-us"
              className="text-xl font-bold"
              style={{ marginBottom: 20 }}
            >
              9. Contacting us
            </h3>
            <p
              className="text-base "
              style={{ lineHeight: 2, marginBottom: 20, color: "gray" }}
            >
              If you have any questions, concerns, or complaints regarding this
              Agreement, we encourage you to contact us using the details below:
              <br />
              208 Kings Grant Dr., Goode, VA, 24556, USA
              <br />
              <br />
              This document was last updated on October 3, 2022 <br />
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;
