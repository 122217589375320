import React, { useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import Checkout from "./Checkout";
import { useEffect } from "react";
import axios from "axios";

const stripePromise = loadStripe(
  "pk_live_51JO71BBCUXDJMvIjMtuEjZtcYArv0TVzmAolsSDm1iqzozUElDc8SziuVnfhxg53t7IDzrIgq3QhwxeN2ylQCCDu00Xy9RoV8k"
);

const successMessage = () => {
  return (
    <div className="success-msg">
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        className="bi bi-check2"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"
        />
      </svg>
      <div className="title">Payment Successful</div>
    </div>
  );
};

function StripePayment() {
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const [clientSecret, setClientSecret] = useState("")
  const [isLoading, setLoading] = useState(true);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  useEffect(() => {
    //console.log("Token :::: ", localStorage.getItem("token"));
    const token = localStorage.getItem("token");
    axios
      .get(`http://www.novasatus.life/api/v1/paymennt/secret/`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        setClientSecret(response.data.client_secret)
       // console.log(response.data.client_secret)
        setLoading(!isLoading);
      })
      .catch(console.log);
  }, []);

  //console.log(clientSecret)

  return (
    <div className="container">
      <div className="py-5 text-center">
        <div className="">
          <h3 className="px-14 pt-6 text-2xl font-semibold pb-2 text-center">
            Payment
          </h3>
        </div>
        <div>
          <p className="text-center font-semibold text-opacity-70 pb-0">
            Enter your Payment details <br />
            for subscription.
          </p>
        </div>
      </div>

      <div className="row s-box">
        {paymentCompleted ? (
          successMessage()
        ) : (
          <React.Fragment>
            {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <Checkout setPaymentCompleted={setPaymentCompleted}/>
        </Elements>
      )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

export default StripePayment;
