import React, {useState, useEffect} from "react";
import {Link, useHistory} from "react-router-dom";
import {connect, useDispatch} from "react-redux";
import {auth} from "../Store/Actions/Auth";
import Button from "../components/Button";
import Header from "../components/Header";
import eye from "../assets/images/eye.svg";
import hide from "../assets/images/hide.svg";
import reg from "../Store/Actions/Registration";
import toast from 'react-hot-toast';
import "./signup.css";
import Actions from "../Store/Type";

const Signup = (props) => {

  const [show, setShow] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const [registration, setRegistration] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    password: "",
    password2: "",
  });

  const dispatch = useDispatch();
  const history = useHistory();

  let Red = props.redirectToLogin;
  useEffect(() => {
    if (props.redirectToLogin) {
      toast("Successfully Signed Up")
      history.push("/login");
    }
  }, [Red, props.redirectToLogin]);

  const handleSignup = (e) => {
    e.preventDefault();
    if (registration.password !== registration.password2) {
      toast("Passwords donot match");
      return;
    }
    if (registration.password.length < 8 || registration.password2.length < 8) {
      toast("Password lenght should be greater than 8 or 8");
      return;
    }
    if (registration.phone_number.length < 11) {
      toast("Phone number should contain atleast 11 numbers!");
      return;
    }
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (regex.test(registration.email) == false) {
      console.log("registration email check ==--== ", regex.test(registration.email));
      toast("Email is not valid")
      return
    }

    if (/\s/.test(registration.first_name)) {
      toast("First name should not have last name")
      return
    }


    reg({
      name: props.user,
      email: registration.email,
      company_name: "student",
      street: "no-street",
      city: "no-city",
      country: "no-country",
      tax_id: "no-tax-id",
      phone_number: registration.phone_number,
      first_name: registration.first_name,
      last_name: registration.last_name,
      user_type: "student",
      password: registration.password,
      password2: registration.password2,
    }).then(function (response) {
      console.log("checking response ==-== ", response);
      dispatch({
        type: Actions.SIGNUP,
        payload: response,
      });
    })
      .catch(function (error) {
        if (error.response.data) {
          for (let key in error.response.data) {
            toast(`${key}: ${error.response.data[key][0]}`)
            return
          }
        } else if (error != null && error != undefined) {
          toast("Signup Failed! \n" + error)
        }
      });
  };

  const styles = {
    iconStyle: {right: 12, top: 8, position: 'absolute', height: '28px'},
    inputPassword: {position: 'relative', width: '100%', display: 'flex',}
  }

  return (
    <div className="signup-root-one">
      <Header/>
      <div className="signup-root-container rounded-2xl">
        <div className="text-center font-semibold text-2xl pb-4">
          <h1>Sign Up As a Student</h1>
        </div>
        <form
          onSubmit={handleSignup}
          className="flex text-lg"
          style={{flexFlow: 'column'}}
        >
          <div className="row">
            <div
              className="col-md-6 col-lg-6 col-sm-12 flex justify-center items-center"
              style={{flexFlow: 'column'}}>
              <input
                style={{
                  background: "#F1F6FB", width: '100%',
                  height: '5vh'
                }}
                className="pl-2 rounded-md font-medium py-1 mx-8 my-2"
                placeholder="First Name"
                required
                value={registration.first_name}
                onChange={(e) =>
                  setRegistration({...registration, first_name: e.target.value})
                }
              />
              <input
                style={{
                  background: "#F1F6FB", width: '100%',
                  height: '5vh'
                }}
                className="pl-2 rounded-md font-medium py-1 mx-8 my-2"
                placeholder="Enter Email"
                required
                value={registration.email}
                onChange={(e) =>
                  setRegistration({...registration, email: e.target.value})
                }
              />
              {/* ==--== */}
              <div style={styles.inputPassword}>
                <input
                  style={{background: "#F1F6FB", width: '100%', height: '5vh'}}
                  className="pl-2 rounded-md font-medium py-1"
                  placeholder="Password"
                  required
                  value={registration.password}
                  type={visibility ? "text" : "password"}
                  onChange={(e) =>
                    setRegistration({...registration, password: e.target.value})
                  }
                />
                {visibility ? (
                  <img
                    src={eye}
                    style={styles.iconStyle}
                    className="cursor-pointer"
                    onClick={() => setVisibility(false)}
                  />
                ) : (
                  <img
                    src={hide}
                    style={styles.iconStyle}
                    className="cursor-pointer"
                    onClick={() => setVisibility(true)}
                  />
                )}
              </div>

            </div>

            <div className="col-md-6 col-lg-6 col-sm-12 flex justify-center items-center"
                 style={{flexFlow: 'column'}}>
              <input
                style={{
                  background: "#F1F6FB", width: '100%',
                  height: '5vh'
                }}
                className="pl-2 rounded-md font-medium py-1 mx-8 my-2"
                placeholder="Last Name"
                required
                value={registration.last_name}
                onChange={(e) =>
                  setRegistration({...registration, last_name: e.target.value})
                }
              />
              <input
                style={{
                  background: "#F1F6FB", width: '100%',
                  height: '5vh'
                }}
                className="pl-2 rounded-md font-medium py-1 mx-8 my-2"
                placeholder="Phone Number"
                required
                value={registration.phone_number}
                onChange={(e) =>
                  setRegistration({...registration, phone_number: e.target.value})
                }
              />
              {/* ==--== */}
              <div style={styles.inputPassword}>
                <input
                  style={{background: "#F1F6FB", width: '100%', height: '5vh'}}
                  className="pl-2 rounded-md font-medium py-1"
                  placeholder="Confirm Password"
                  required
                  value={registration.password2}
                  type={show ? "text" : "password"}
                  onChange={(e) =>
                    setRegistration({...registration, password2: e.target.value})
                  }
                />
                {show ? (
                  <img
                    src={eye}
                    style={styles.iconStyle}
                    className="cursor-pointer"
                    onClick={() => setShow(false)}
                  />
                ) : (
                  <img
                    src={hide}
                    style={styles.iconStyle}
                    className="cursor-pointer"
                    onClick={() => setShow(true)}
                  />
                )}
              </div>

            </div>
            <div className="flex flex-col col-12 justify-center items-center mt-5 mb-5">
              <div className="justify-center bg-vector10 space-y-4">
                <Button
                  type="submit"
                  buttonStyle="btn--major--solid"
                  buttonSize="btn--medium"
                >
                  Sign up
                </Button>
              </div>

              <div className="flex space-x-1 px-10 justify-center">
                <p className="">By using Nova Satus you agree to our </p>
                <p className="text-color">
                  <Link to="/terms" className="card-links">
                    Terms
                  </Link>
                </p>
              </div>
            </div>
          </div>

        </form>
      </div>
    </div>
  );
};

// const InputField = (props) => {
//   const [show, setShow] = useState(false)
//   return (
//     <div style={{ backgroundColor: 'green', display: 'flex', flexDirection: 'row' }}>
//       <input
//         style={{
//           background: "#F1F6FB", width: '100%',
//           height: '5vh'
//         }}
//         className="pl-2 font-medium py-1"
//         placeholder="Confirm Password"
//         required
//         value={props.value}
//         type={show ? "text" : "password"}
//         onChange={props.onChange}
//       />
//       <img
//         src={show ? hide : eye}
//         className="signup-pass2-eye cursor-pointer"
//         onClick={() => setShow(!show)}
//       />
//     </div>
//   )
// }

const mapStateToProps = (state) => {
  return {
    isValid: state.reg.isValid,
    redirectToLogin: state.reg.redirectToLogin,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, {reg, auth})(Signup);
