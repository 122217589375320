import message from "../assets/images/message 1.png";
import Header from "../components/Header";

const ResetPass2 = () => {
  return (
    <div className="resetpass-root-one">
      <Header />
      <div className="resetpass-root-container rounded-2xl">
        <div className="flex justify-center">
          <img src={message} />
        </div>
        <div className="">
          <h3 className="px-14 pt-6 text-xl font-semibold pb-6 text-center">
            Successful Password Reset
          </h3>
        </div>
        <div className="flex flex-col mx-6 space-y-6">
          <p className="text-center">
            Please check your email, an instruction <br /> letter will be sent
            to it.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ResetPass2;
