import React, { useEffect, useState, useContext } from "react";
import { TypeContext } from "../context/TypeContext";
import { useHistory } from "react-router";
import Button from "../components/Button";
import { connect } from "react-redux";
import { auth } from "../Store/Actions/Auth";

const Account = (props) => {
  const history = useHistory();

  /*Random commit */

  useEffect(() => {
    if (
      props.accessToken === null ||
      props.accessToken === "" ||
      props.accessToken === undefined
    ) {
      history.push("/");
    }
  }, []);

  return (
    <div className="flex flex-col justify-around my-24 items-center">
      <p
        style={{
          fontWeight: 600,
          fontSize: 28,
          width: "82%",
          marginBottom: "20px",
        }}
      >
        Account Approvals
      </p>
      <table class="table" style={{ width: "80%" }}>
        <thead>
          <tr>
            <th scope="col" style={{ background: "rgb(245,245,245)" }}>
              Account
            </th>
            <th scope="col" style={{ background: "rgb(245,245,245)" }}>
              Decision
            </th>
          </tr>
        </thead>
        <tbody>
          {[...Array(10)].map((a, i) => {
            return (
              <tr>
                <td style={{ color: "gray" }}>{`Edward King ${i}`}</td>
                {/* <td style={{ color: "gray" }}>{`Trustee ${i}`}</td>
                 */}
                <td className="flex space-x-4">
                  {/* <button className="p-2 rounded-md text-yellow-50 bg-gradient-to-r from-vector10 via-primary to-primary">
                    APPROVE
                  </button> */}
                  {/* <button
                    className="border-4 border-vector10 p-2"
                    style={{ color: "#FE5C15" }}
                  >
                    DENY
                  </button> */}
                  <Button
                    onClick={() => {}}
                    type="button"
                    buttonStyle="btn--major--solid"
                    buttonSize="btn--medium"
                  >
                    APPROVE
                  </Button>
                  <Button
                    buttonStyle="btn--minor--outline"
                    buttonSize="btn--medium"
                    onClick={() => {}}
                    type="button"
                  >
                    DENY
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    redirectTo: state.auth.redirectTo,
    invalidUser: state.auth.invalidUser,
    accessToken: state.auth.accessToken,
    isType: state.auth.isType,
  };
};

export default connect(mapStateToProps, { auth })(Account);
