import React, { useState } from "react";
import { useStyles } from "./PaginationStyle";

const NewPagination = ({ postsPerPage, totalPosts, paginate }) => {
  const classes = useStyles();

  const pageNumbers = [];

  const [currentPage, setCurrentPage] = useState(1);

  console.log(`total posts ${totalPosts} ${postsPerPage}`);

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  console.log(currentPage);

  const backwardChange = () => {
    setCurrentPage(currentPage - 1);
    paginate(currentPage - 1);
  };

  const forwardChange = () => {
    setCurrentPage(currentPage + 1);
    paginate(currentPage + 1);
  };

  return (
    <nav className={classes.mainPag}>
      <div className={classes.pagination}>
        <button
          color="secondary"
          disabled={currentPage === 1}
          onClick={() => (currentPage > 0 ? backwardChange() : null)}
          className={classes.Previous}
        >
          {`<`}
        </button>

        {pageNumbers.map((number) => (
          <p key={number} className={classes.pageItem}>
            <button
              onClick={() => {
                paginate(number);
                setCurrentPage(number);
              }}
              className={classes.pageBtn}
            >
              {number}
            </button>
          </p>
        ))}
        <button
          color="secondary"
          className={classes.Previous}
          style={{ marginLeft: "20px" }}
          disabled={currentPage > pageNumbers.length - 1}
          onClick={() => forwardChange()}
        >
          {`>`}
        </button>
      </div>
    </nav>
  );
};

export default NewPagination;
