import Card from "../components/Card";
import Header from "../components/Header";
import Button from "../components/Button";

import cv1 from "../assets/images/cv 1.png";
import cv2 from "../assets/images/personal-data2.png";
import { useContext, useEffect, useState } from "react";
import { TypeContext } from "../context/TypeContext";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { auth } from "../Store/Actions/Auth";
import Actions from "../Store/Type";

const HomePage = ({ redirectTo, accessToken }) => {
  const [redirect, setRedirect] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setRedirect(redirectTo);
    if (redirect) {
      history.push(redirectTo);
    }
  }, [redirectTo, redirect]);

  useEffect(() => {
    if (!accessToken) {
      history.push("/");
    }
  }, [accessToken]);

  // const logout = () => {
  //   dispatch({
  //     type: Actions.LOGOUT,
  //   });
  // };

  return (
    <div className="root-one">
      <Header />
      <div className="law-root-container">
        <div className="flex justify-end m-2">
          {/* <Button
            buttonStyle="btn--major--solid"
            buttonSize="btn--small"
            onClick={logout}
          >
            LOGOUT
          </Button> */}
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 items-center">
          <Card
            imgsrc={cv1}
            label="Law Firms and Clients"
            description="See the list of all law firms and their clients"
            buttonLabel="View List"
            link="/lawfirms"
            smallButton={true}
          />
          <Card
            imgsrc={cv2}
            label="Users"
            description="See a list of individual users and their assigned trustees"
            buttonLabel="View List"
            link="/users"
            smallButton={true}
          />
          <Card
            imgsrc={cv1}
            label="Accounts"
            description="See a list of requests for a new account"
            buttonLabel="View List"
            link="/accounts"
            smallButton={true}
          />

          <Card
            imgsrc={cv2}
            label="Subsciption"
            description="See a list of all subscriptions and payments options available"
            buttonLabel="View List"
            link="/subscriberList"
            smallButton={true}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    redirectTo: state.auth.redirectTo,
    accessToken: state.auth.accessToken,
  };
};

export default connect(mapStateToProps, { auth })(HomePage);
