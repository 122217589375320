import Actions from "../Type.js";

const initialState = {
  isValid: false,
  redirectToLogin: false,
};

const RegReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case Actions.SIGNUP:
      return {
        ...state,
        isValid: true,
        redirectToLogin: true,
      };

    case Actions.SIGNUP_FAILED:
      return {
        ...state,
        isValid: false,
        redirectToLogin: false,
      };

    default:
      return state;
  }
};

export default RegReducer;
