import Actions from "../Type.js";

const initialState = {
  isValid: false,
  borrower_id: localStorage.getItem("borrower_id"),
};

const userUpdateReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case Actions.UPDATE_SUCCESS:

      localStorage.setItem("borrower_id", payload.user.borrower_id);
      console.log("id---", payload.user.borrower_id);
      return {
        ...state,
        borrower_id: payload.user.borrower_id,

      };

    case Actions.UPDATE_FAILED:
      return {
        ...state,
        // isValid: false,
        // redirectToLogin: false,
      };

    default:
      return state;
  }
};

export default userUpdateReducer;
